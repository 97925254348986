import { graphql, PageProps } from "gatsby"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import * as React from "react"
import "twin.macro"
import Container from "../components/Container"
import Markdown from "../components/Markdown"
import Seo from "../components/seo"

interface AboutProps extends PageProps {
  data: {
    file?: {
      childMarkdownRemark?: {
        frontmatter: {
          title: string
          intro: string
          image: {
            childImageSharp: {
              gatsbyImageData: ImageDataLike
            }
          }
        }
      }
    }
  }
}

const Index = ({ data }: AboutProps) => {
  const { intro, image } = data.file?.childMarkdownRemark?.frontmatter ?? {
    intro: "",
  }

  const imageData =
    image !== null && image !== undefined
      ? getImage(image.childImageSharp.gatsbyImageData)
      : null

  return (
    <div>
      <Seo title="About" />
      <Container>
        <div tw="flex flex-col xl:flex-row items-start">
          {imageData && (
            <GatsbyImage
              image={imageData}
              alt="About image"
              tw="max-w-prose mb-8 xl:max-w-full xl:mr-16"
            />
          )}
          <div tw="prose">
            <Markdown content={intro} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    file(sourceInstanceName: { eq: "pages" }, name: { eq: "about" }) {
      childMarkdownRemark {
        frontmatter {
          intro
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
